import gsap from 'gsap';
import Ticker from '../../core/Ticker';
import * as utils from '../../utils';

import { store } from '../../store';

export default class Ribbon extends Ticker {
    constructor() {
        super();

        this.ribbon = document.querySelector('.ribbon');

        this.progress = 0;
        this.multiplier = 2.4;
        this.listeners = [];
        this.bounds = null;
    }

    mount = () => {
        this.addTicker();
        this.listeners.push(utils.listen(window, 'resize', this.getBounds));
        this.translateSetter = gsap.quickSetter(this.ribbon, 'translateX', 'px');
    };

    destroy = () => {
        this.removeTicker();
        this.listeners.forEach(el => el.remove());
    };

    getBounds = () => {
        this.ribbon.offsetHeight;
        this.bounds = this.ribbon.firstElementChild.getBoundingClientRect();
    };

    calculateScrollSpeed = () => {
        const baseSpeed = (1 + Math.abs(store.scroll.scrollSpeed) * 50) * this.multiplier;

        if (!store.snake) return baseSpeed;

        const snakeAdjustment = store.cursor.mouseSpeed * 0.1;

        return (1 + Math.abs(store.scroll.scrollSpeed + snakeAdjustment) * 50) * this.multiplier;
    };

    updateTicker = () => {
        if (!utils.inView(this.ribbon)) return;

        if (!this.bounds) this.getBounds();

        const dt = gsap.ticker.deltaRatio();

        const scrollSpeed = this.calculateScrollSpeed();

        this.progress -= scrollSpeed * dt;

        if (Math.abs(this.progress) >= this.bounds.width) {
            this.progress = 0;
        }

        this.translateSetter(this.progress);
    };
}
