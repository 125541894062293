import * as utils from '../../utils';

import gsap from 'gsap';
import Ticker from '../../core/Ticker';

import { GAME, SIZES } from '../../constant';
import { store } from '../../store';
import CursorGameTriggers from './CursorGameTrigger';

export default class Cursor extends Ticker {
    constructor() {
        super();

        this.current = { x: 0, y: 0 };
        this.last = { x: 0, y: 0 };
        this.bounds = { x: 0, y: 0 };

        this.mouse = { x: window.innerWidth / 2.25, y: window.innerHeight / 1.8 };
        this.ease = 0.12;
        this.mouseSpeed = 0;

        this.footer = document.querySelector('.footer');
        this.cursor = document.querySelector('.cursor');
        this.cursorDotPath = '.cursor__dot circle';
        this.cursorCircle = '.cursor__circle';
        this.cursorDot = '.cursor__dot';
        this.cursorBubble = document.querySelector('.cursor__bubble');
        this.cursorTextBubble = document.querySelector('.cursor__text-bubble');
        this.cursorCirclePath = document.querySelector('.cursor__circle-path');
        this.dataHover = document.querySelectorAll('[data-cursor-hover]');
        this.dataBubble = document.querySelectorAll('[data-cursor-bubble]');
        this.circlePath = this.cursorCirclePath.firstElementChild.getTotalLength() + 1;

        this.gameTrigger = new CursorGameTriggers(
            this.cursorCirclePath,
            this.circlePath,
            this.cursorDotPath,
            this.bounds
        );

        this.cursorListeners = [];
        this.globalListeners = [];
    }

    mount = () => {
        this.initStyles();
        this.addTicker();
        this.addListeners();

        this.yBubbleSetter = gsap.quickSetter(this.cursorBubble, 'y', 'px');
        this.xBubbleSetter = gsap.quickSetter(this.cursorBubble, 'x', 'px');
        this.xCircleSetter = gsap.quickSetter(this.cursorCircle, 'x', 'px');
        this.yCircleSetter = gsap.quickSetter(this.cursorCircle, 'y', 'px');
        this.xDotSetter = gsap.quickSetter(this.cursorDot, 'x', 'px');
        this.yDotSetter = gsap.quickSetter(this.cursorDot, 'y', 'px');
        this.scaleXSetter = gsap.quickSetter(this.cursorCirclePath, 'scaleX');
        this.rotateSetter = gsap.quickSetter(this.cursorCirclePath, 'rotate', 'deg');
    };

    destroy = () => {
        this.removeTicker();
        this.cursorListeners.forEach(el => el.remove());
        this.globalListeners.forEach(el => el.remove());
    };

    displayCursor = () => {
        if (utils.isDevice() || window.innerWidth <= SIZES.s) {
            gsap.to('.cursor', { opacity: 0 });
        } else {
            gsap.to('.cursor', { opacity: 1 });
        }
    };

    initStyles = () => {
        gsap.set(this.cursorBubble, {
            scale: 0
        });

        gsap.set([this.cursorCircle, this.cursorDot, this.cursorBubble], {
            xPercent: -50,
            yPercent: -50
        });

        gsap.set(this.cursorCirclePath, {
            strokeDasharray: `${this.circlePath} ${this.circlePath}`
        });
    };

    updateMouseCoordinates = ({ clientX, clientY }) => {
        this.mouse.x = clientX;
        this.mouse.y = clientY;
    };

    calcMouseSpeed = (deltaX, deltaY) => {
        const treshhold = 30;
        const vX = utils.clamp(Math.abs(deltaX) / treshhold, 0, 1);
        const vY = utils.clamp(Math.abs(deltaY) / treshhold, 0, 1);

        this.mouseSpeed = vX + vY;

        if (this.mouseSpeed < 0.01) this.mouseSpeed = 0;
    };

    calcStretchFx = (deltaX, deltaY) => {
        if (this.gameTrigger.state === GAME.stopped) {
            const rotation = Math.atan2(deltaY, deltaX) * (180 / Math.PI).toFixed();

            this.rotateSetter(rotation);
            this.scaleXSetter(1 + this.mouseSpeed);
        } else {
            this.rotateSetter(0);
            this.scaleXSetter(1);
        }
    };

    smoothCursorPosition = () => {
        const t = utils.getDeltaTime(this.ease);

        this.last.x = this.current.x;
        this.last.y = this.current.y;

        if (this.bounds.x) {
            this.current.x = utils.lerp(this.last.x, this.bounds.x, t);
            this.xDotSetter(this.current.x + (this.bounds.x - this.last.x) * t);
        } else {
            this.current.x = utils.lerp(this.last.x, this.mouse.x, t);
            this.xDotSetter(this.current.x + (this.mouse.x - this.last.x) * t);
        }

        if (this.bounds.y) {
            this.current.y = utils.lerp(this.last.y, this.bounds.y, t);
            this.yDotSetter(this.current.y + (this.bounds.y - this.last.y) * t);
        } else {
            this.current.y = utils.lerp(this.last.y, this.mouse.y, t);
            this.yDotSetter(this.current.y + (this.mouse.y - this.last.y) * t);
        }

        this.xCircleSetter(this.current.x);
        this.yCircleSetter(this.current.y);
        this.xBubbleSetter(this.current.x);
        this.yBubbleSetter(this.current.y);
    };

    updateTicker = () => {
        this.smoothCursorPosition();

        const deltaX = this.current.x - this.last.x;
        const deltaY = this.current.y - this.last.y;

        this.calcMouseSpeed(deltaX, deltaY);
        this.calcStretchFx(deltaX, deltaY);
    };

    stickToElement = bounds => {
        this.bounds = {
            x: bounds.x - 5,
            y: bounds.y + 3 + bounds.height / 2
        };

        gsap.to(this.cursorDotPath, {
            scale: 0.7,
            ease: 'expo.out'
        });

        gsap.to(this.cursorCirclePath, {
            opacity: 0,
            duration: 1,
            ease: 'expo.out',
            strokeDashoffset: `${this.circlePath}`
        });
    };

    followElements = bounds => {
        this.bounds = {
            x: bounds.x / 1.025
        };

        gsap.to(this.cursorCirclePath, {
            opacity: 0,
            duration: 1,
            ease: 'expo.out',
            strokeDashoffset: `${this.circlePath}`
        });
    };

    ringToElement = bounds => {
        this.bounds = {
            x: bounds.x + bounds.width / 2,
            y: bounds.y + bounds.height / 2 + 2
        };

        gsap.to(this.cursorDotPath, {
            scale: 0
        });
    };

    onShowBubble = ({ currentTarget }) => {
        const opt = JSON.parse(currentTarget.getAttribute('data-cursor-bubble'));

        if (opt[3]) {
            if (window.innerWidth <= SIZES[opt[3].split('-')[1]]) {
                return;
            }
        }

        this.cursorTextBubble.innerHTML = opt[0];

        gsap.set([this.cursorCirclePath, this.cursorDotPath], {
            opacity: 0
        });

        gsap.set(this.cursorBubble, {
            backgroundColor: `var(${opt[1]})`,
            color: `var(${opt[2]})`
        });

        gsap.to(this.cursorBubble, {
            scale: 1,
            ease: 'expo.out',
            duration: 0.5
        });
    };

    onHideBubble = () => {
        this.cursorTextBubble.innerHTML = null;

        gsap.set(this.cursorBubble, {
            background: 'var(--color-accent)'
        });

        gsap.set([this.cursorCirclePath, this.cursorDotPath], {
            opacity: 1
        });

        gsap.to(this.cursorBubble, {
            scale: 0,
            ease: 'expo.out',

            onComplete: () => {
                delete this.cursor.dataset.active;
            }
        });
    };

    onDataHoverMove = ({ currentTarget }) => {
        if (this.gameTrigger.state === GAME.starting) {
            this.gameTrigger.interuptStart();
            return;
        }

        this.cursor.dataset.active = true;

        const attributeType = currentTarget.getAttribute('data-cursor-hover');

        const bounds = currentTarget.getBoundingClientRect();

        if (attributeType === 'stick') {
            this.stickToElement(bounds);
        } else if (attributeType === 'follow') {
            this.followElements(bounds);
        } else if (attributeType === 'ring') {
            this.ringToElement(bounds);
        }
    };

    onDataHoverLeave = () => {
        if (this.gameTrigger.state === GAME.starting) {
            this.gameTrigger.interuptStart();
            return;
        }

        this.bounds = { x: 0, y: 0 };

        gsap.to(this.cursorCirclePath, {
            opacity: 1,
            duration: 1,
            ease: 'expo.out',
            strokeDashoffset: 0,

            onComplete: () => {
                delete this.cursor.dataset.active;
            }
        });

        gsap.to(this.cursorDotPath, {
            scale: 1
        });
    };

    onMouseUp = ({ button, target }) => {
        if (utils.isDevice() || window.innerWidth <= SIZES.s) return;

        if (button === 2 || this.cursor.dataset.active) {
            return;
        }

        if (target.closest('.game-area')) {
            if (this.gameTrigger.state === GAME.running) this.gameTrigger.stop();
            if (this.gameTrigger.state === GAME.stopped && store.snake === undefined)
                this.gameTrigger.start();
        }
    };

    onMouseDown = ({ target }) => {
        if (utils.isDevice() || window.innerWidth <= SIZES.s) return;

        if (target.closest('.game-area')) {
            if (this.gameTrigger.state === GAME.stopping) this.gameTrigger.interuptStop();
            if (this.gameTrigger.state === GAME.starting) this.gameTrigger.interuptStart();
        }
    };

    addListeners = () => {
        this.cursorListeners.push(utils.listen(window, 'mousemove', this.updateMouseCoordinates));

        this.cursorListeners.push(utils.listen(window, ['resize', 'load'], this.displayCursor));

        this.dataHover.forEach(el => {
            this.cursorListeners.push(utils.listen(el, 'mousemove', this.onDataHoverMove));
            this.cursorListeners.push(utils.listen(el, 'mouseleave', this.onDataHoverLeave));
        });

        this.dataBubble.forEach(el => {
            this.cursorListeners.push(utils.listen(el, 'mouseenter', this.onShowBubble));
            this.cursorListeners.push(utils.listen(el, 'mouseleave', this.onHideBubble));
        });

        if (this.footer) {
            if (navigator.maxTouchPoints > 0) {
                this.cursorListeners.push(utils.listen(window, 'touchend', this.onMouseDown));
                this.cursorListeners.push(utils.listen(window, 'touchstart', this.onMouseUp));
            } else {
                this.cursorListeners.push(utils.listen(window, 'mouseup', this.onMouseDown));
                this.cursorListeners.push(utils.listen(window, 'mousedown', this.onMouseUp));
            }
        }
    };
}
