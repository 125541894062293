import gsap from 'gsap';
import * as utils from '../../utils';

export default class TextHover {
    constructor() {
        this.textHover = [...document.querySelectorAll('.text-hover')];
        this.directions = [
            { position: 'afterbegin', val: 100 },
            { position: 'beforeend', val: -100 }
        ];

        this.listeners = [];
    }

    mount = () => {
        this.textHover.forEach(el => {
            el.childNodes.forEach(content => {
                this.listeners.push(
                    utils.listen(content, 'mousemove', event => this.onHover(event, content))
                );
            });
        });
    };

    destroy = () => {
        this.listeners.forEach(listener => listener.remove());
    };

    getHoverDirection = (event, content) => {
        const { left, width } = content.getBoundingClientRect();
        const index = event.clientX - left < width / 2 ? 0 : 1;

        return { ...this.directions[index] };
    };

    cloneChar = (content, direction, char) => {
        content.insertAdjacentHTML(
            direction.position,
            `<span class="text-split__letter clone ${direction.position}">${char}</span>`
        );
    };

    stretchChar = (content, direction) => {
        gsap.to(content.children, {
            scaleX: 1.23,
            duration: 1,
            transformOrigin: `${direction.position === 'beforeend' ? 'right' : 'left'}`,
            ease: 'expo.inOut',
            yoyo: true,
            repeat: 1
        });
    };

    translateChars = (content, direction) => {
        gsap.to(content.children, {
            xPercent: direction.val,
            duration: 1.8,
            ease: 'customEase',
            yoyo: true,
            repeat: 1,
            onStart: () => {
                content.dataset.active = true;
            },
            onComplete: () => {
                delete content.dataset.active;

                const clone = content.querySelector('.clone');

                if (clone) clone.remove();
            }
        });
    };

    animate = (content, direction, char) => {
        gsap.killTweensOf(content.children);

        if (content.children.length === 1) {
            this.stretchChar(content, direction);
            this.cloneChar(content, direction, char);
        }

        if (content.children.length === 2) {
            this.translateChars(content, direction);
        }
    };

    onHover = (event, content) => {
        if (content.dataset.active) return;

        const direction = this.getHoverDirection(event, content);
        const char = content.firstChild.textContent;

        this.animate(content, direction, char);
    };
}
